import { Button } from "@izone/ui/components/button";
import { useNavigate } from "@remix-run/react";

export default function ErrorPage() {
  const navigate = useNavigate();

  const onBack = () => navigate(-1);
  return (
    <section className="absolute inset-0 grid h-full place-items-center bg-white dark:bg-gray-900">
      <div className="mx-auto max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="text-primary-600 dark:text-primary-500 mb-4 font-mono text-7xl font-extrabold tracking-tight lg:text-9xl">
            500
          </h1>
          <p className="mb-4 flex flex-row items-center justify-center gap-2 text-3xl font-bold tracking-tight text-gray-900 md:text-4xl dark:text-white">
            Đã có lỗi xảy ra
          </p>
          <p className="mb-4 max-w-lg px-6 font-light text-gray-500 lg:px-0 lg:text-lg dark:text-gray-400">
            Chúng tôi sẽ cố gắng để khắc phục trong thời gian sớm nhất. Bạn vui
            lòng liên hệ với bộ phận chăm sóc khách hàng để được hỗ trợ.{" "}
          </p>
          <Button
            onClick={onBack}
            className="my-4 inline-flex rounded-lg bg-primary px-5 py-2.5 text-center text-base font-medium text-white hover:bg-primary focus:outline-none focus:ring-4 focus:ring-primary dark:focus:ring-primary"
          >
            Quay trở lại
          </Button>
        </div>
      </div>
    </section>
  );
}
